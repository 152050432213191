import jwtDecode from 'jwt-decode';

type UserTokenData = {
  id: string;
  authId: number;
  accountType: string;
  iat: number;
  exp: number;
  isAccountActivated: boolean;
};

export const isTokenValid = (): boolean => {
  const token = localStorage.getItem('token') as string;
  const tokenOfUser = token ? (jwtDecode(token) as UserTokenData) : null;
  if (tokenOfUser?.exp) {
    const { exp } = tokenOfUser;
    const currentTime = Date.now() / 1000;
    if (exp < currentTime) {
      return false;
    }
    return true;
  }
  return false; // Default return value if tokenOfUser?.exp is falsy
};

export const userDecodedDetail = () => {
  const token = localStorage.getItem('token') as string;
  const tokenOfUser = token ? (jwtDecode(token) as UserTokenData) : null;
 
  return {
    authId: tokenOfUser?.authId,
    isAccountActivated: tokenOfUser?.isAccountActivated,
    accountType: tokenOfUser?.accountType
  }
};
