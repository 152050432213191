import { createContext, useCallback, useContext, useState } from 'react';
import { createPortal } from 'react-dom';

type Toast = {
  id: number;
  message: string;
  type: 'success' | 'error' | 'info';
};

type ToastContextType = {
  addToast: (message: string, type: Toast['type']) => void;
  removeToast: (id: number) => void;
};

const ToastContext = createContext<ToastContextType | null>(null);

function ToastItem({
  message,
  type,
  onClose,
}: {
  message: string;
  type: Toast['type'];
  onClose: () => void;
}) {
  return (
    <div
      className={`
        animate-slide-in fixed right-5 top-4 z-[999999] flex w-[400px] items-center justify-between gap-2 rounded-[8px] border-[1px] border-[#D0D5DD] px-5 py-4
        drop-shadow-lg
        ${type === 'success' ? 'bg-white text-green-800' : ''}
        ${type === 'error' ? 'bg-red-50 text-red-800' : ''}
        ${type === 'info' ? 'bg-blue-50 text-blue-800' : ''}
      `}
      role="alert"
    >
      <div className="flex items-center gap-2">
        <div className="">
          {type === 'success' ? (
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3">
                <rect
                  x="6"
                  y="6"
                  width="26"
                  height="26"
                  rx="13"
                  stroke="#079455"
                  strokeWidth={'2'}
                />
              </g>
              <g opacity="0.1">
                <rect
                  x="1"
                  y="1"
                  width="36"
                  height="36"
                  rx="18"
                  stroke="#079455"
                  strokeWidth={'2'}
                />
              </g>
              <g clipPath="url(#clip0_6049_114028)">
                <path
                  d="M15.25 19L17.75 21.5L22.75 16.5M27.3333 19C27.3333 23.6024 23.6024 27.3333 19 27.3333C14.3976 27.3333 10.6667 23.6024 10.6667 19C10.6667 14.3976 14.3976 10.6667 19 10.6667C23.6024 10.6667 27.3333 14.3976 27.3333 19Z"
                  stroke="#079455"
                  strokeWidth={'1.66667'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_6049_114028">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(9 9)"
                  />
                </clipPath>
              </defs>
            </svg>
          ) : type === 'info' ? (
            <svg
              className="w-6 h-6 text-red-600"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  d="M12.5 16V14.5M12.5 9V13M20.5 12.5C20.5 16.9183 16.9183 20.5 12.5 20.5C8.08172 20.5 4.5 16.9183 4.5 12.5C4.5 8.08172 8.08172 4.5 12.5 4.5C16.9183 4.5 20.5 8.08172 20.5 12.5Z"
                  stroke="#140ad7"
                  strokeWidth="1.2"
                ></path>{' '}
              </g>
            </svg>
          ) : (
            type === 'error' && (
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 16.00 16.00"
                xmlns="http://www.w3.org/2000/svg"
                fill="#b30808"
                stroke="#b30808"
                strokeWidth="0.00016"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#CCCCCC"
                  strokeWidth="0.096"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.6 1c1.6.1 3.1.9 4.2 2 1.3 1.4 2 3.1 2 5.1 0 1.6-.6 3.1-1.6 4.4-1 1.2-2.4 2.1-4 2.4-1.6.3-3.2.1-4.6-.7-1.4-.8-2.5-2-3.1-3.5C.9 9.2.8 7.5 1.3 6c.5-1.6 1.4-2.9 2.8-3.8C5.4 1.3 7 .9 8.6 1zm.5 12.9c1.3-.3 2.5-1 3.4-2.1.8-1.1 1.3-2.4 1.2-3.8 0-1.6-.6-3.2-1.7-4.3-1-1-2.2-1.6-3.6-1.7-1.3-.1-2.7.2-3.8 1-1.1.8-1.9 1.9-2.3 3.3-.4 1.3-.4 2.7.2 4 .6 1.3 1.5 2.3 2.7 3 1.2.7 2.6.9 3.9.6zM7.9 7.5L10.3 5l.7.7-2.4 2.5 2.4 2.5-.7.7-2.4-2.5-2.4 2.5-.7-.7 2.4-2.5-2.4-2.5.7-.7 2.4 2.5z"
                  ></path>
                </g>
              </svg>
            )
          )}
        </div>
        <span className="text-sm font-medium">{message}</span>
      </div>
      <button
        onClick={onClose}
        className="ml-4 text-gray-400 hover:text-gray-600 focus:outline-none"
        aria-label="Close notification"
      >
        <svg
          className="w-4 h-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    (message: string, type: Toast['type'] = 'info') => {
      const id = Date.now();
      setToasts(prev => [...prev, { id, message, type }]);

      setTimeout(() => {
        setToasts(prev => prev.filter(toast => toast.id !== id));
      }, 5000);
    },
    [],
  );

  const removeToast = useCallback((id: number) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {typeof window !== 'undefined' &&
        createPortal(
          <div className="fixed top-0 right-0 z-50 p-4 space-y-4">
            {toasts.map(toast => (
              <ToastItem
                key={toast.id}
                message={toast.message}
                type={toast.type}
                onClose={() => removeToast(toast.id)}
              />
            ))}
          </div>,
          document.body,
        )}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}
