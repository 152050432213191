import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { httpBatchLink, TRPCLink } from '@trpc/client';
import { observable } from '@trpc/server/observable';
import { CustomerAppRouter } from 'api';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from './components/Common/isAuth';
import MainLoader from './components/Common/loader/mainLoader';
import { AmountVisibilityProvider } from './context/balanceVisibility';
import { ModalProvider, ModalProviders } from './context/modalProvider';
import { ToastProvider } from './context/toastContext';
import { trpc } from './lib/trpc';
import AppRoutes from './route';

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 0,
          },
        },
      }),
  );

  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isLoggedOutDueToInactivity, setIsLoggedOutDueToInactivity] =
    useState(false);

  const [trpcClient] = useState(() => {
    const customLink: TRPCLink<CustomerAppRouter> = () => {
      // here we just got initialized in the app - this happens once per app instances
      return ({ next, op }) => {
        // this is when passing the result to the next link
        // each link needs to return an observable which propagates results
        return observable(observer => {
          console.log('performing operation:', op);
          const unsubscribe = next(op).subscribe({
            next(value) {
              observer.next(value);
            },
            error(err) {
              observer.error(err);
              if (err?.data?.code === 'UNAUTHORIZED') {
                logout();
                navigate('/login');
                queryClient.clear();
              }
              new QueryClient({
                defaultOptions: {
                  queries: {
                    refetchOnWindowFocus: false,
                  },
                },
              });
            },
            complete() {
              observer.complete();
            },
          });

          return unsubscribe;
        });
      };
    };

    return trpc.createClient({
      links: [
        customLink,
        httpBatchLink({
          url: import.meta.env.VITE_BACKEND_BASEURL,
          headers: () => {
            const token = localStorage.getItem('token');
            return {
              Authorization: token ? `Bearer ${token}` : undefined,
            };
          },
        }),
      ],
    });
  });

  const logOut = useCallback(() => {
    if (!isLoggedOutDueToInactivity) {
      if (!toast.isActive('inactivityToast')) {
        toast.info('Logged out due to inactivity.', {
          toastId: 'inactivityToast',
        });
      }
      logout();
      navigate('/login');
      setIsLoggedOutDueToInactivity(true);
    }
  }, [navigate, isLoggedOutDueToInactivity]);

  useEffect(() => {
    if (!isAuthenticated || isLoggedOutDueToInactivity) return;

    let inactivityTimer: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      const timeoutDuration =
        process.env.NODE_ENV === 'development'
          ? 2 * 60 * 60 * 1000 // 2 hours for development
          : 15 * 60 * 1000; // 15 minutes for production
      inactivityTimer = setTimeout(logOut, timeoutDuration);
    };

    // Initial setup for inactivity
    resetInactivityTimer();

    // Add event listeners
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);

    // Cleanup function
    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
    };
  }, [isAuthenticated, logOut, isLoggedOutDueToInactivity]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoggedOutDueToInactivity(false);
    }
  }, [isAuthenticated]);

  return (
    <trpc.Provider queryClient={queryClient} client={trpcClient}>
      <QueryClientProvider client={queryClient}>
        {new URLSearchParams(window.location.search).get('devtools') ===
          'true' && <ReactQueryDevtools initialIsOpen={false} />}
        <ToastProvider>
          <AmountVisibilityProvider>
            <ModalProvider>
              <ModalProviders />
              <Suspense fallback={<MainLoader />}>
                <AppRoutes />
              </Suspense>
              <ToastContainer
                position="top-right"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover
                style={{ fontSize: '14px', zIndex: 999999 }}
              />
            </ModalProvider>
          </AmountVisibilityProvider>
        </ToastProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
