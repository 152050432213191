import { useNavigate } from 'react-router-dom';
import { isTokenValid, userDecodedDetail } from '../../components/hooks/auth';
import { useToast } from '../../context/toastContext';
import { trpc } from '../../lib/trpc';

function CantView() {
  const navigate = useNavigate();
  const goBack = () => {
    localStorage.clear();
    navigate('/login')
  }
  const { addToast } = useToast();

  const { authId } = userDecodedDetail();
    const { data: user } = trpc.user.getAccountType.useQuery(
      {},
      { enabled: !!authId },
    );

  const handleNavigate = () => {
    if(isTokenValid() &&
      user?.isAccountActivated &&
      ['CORPORATE', 'INDIVIDUAL', 'TEAM_MEMBER'].includes(user?.acountType as string)) {
      return navigate('/dashboard');
    } else {
      addToast(
        'You do not have access to the requested page',
       'error'
      );
      navigate('/login');
    }
    
  };

  return (
    <div className="h-screen">
      <div className="flex flex-col items-center justify-center min-h-full">
        <div className="mx-auto max-w-[800px] mdxl:w-full mdxl:mx-0 mdxl:px-4">
          <p className="text-[20px]">
            You do not have access to the requested page because you are
            currently signed in. Please click {' '}
            <span
              className="font-[700] underline cursor-pointer"
              onClick={handleNavigate}
            >
              Dashboard
            </span>{' '}
            to return to your dashboard or{' '}
            <span
              className="font-[700] underline cursor-pointer"
              onClick={goBack}
            >
              Go Back
            </span>{' '}
            to return to the previous page.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CantView;
