import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CantView from './errors/cantView';
import { ProtectedRoute, PublicRoute, UnprotectedRoute } from './isAuth';
const ResetLinkError = lazy(
  () => import('../components/Common/errors/resetLinkError'),
);
const Dashboard = lazy(() => import('../components/Dashboard'));
const AuthEmailVerification = lazy(() => import('./auth/emailVerification'));
const AuthLogin = lazy(() => import('./auth/login'));
const AuthLoginOtp = lazy(() => import('./auth/loginOtp'));
const AuthResetEmailSent = lazy(() => import('./auth/resetEmailSent'));
const AuthResetPassword = lazy(() => import('./auth/resetPassword'));
const AuthResetPasswordLink = lazy(() => import('./auth/resetPasswordLink'));
const AuthSignup = lazy(() => import('./auth/signup'));
const GetStarted = lazy(() => import('./dashboard/get-started'));
const DashAccountType = lazy(
  () => import('./dashboard/get-started/account-type'),
);
const HelpandFaq = lazy(() => import('./dashboard/helpandfaq'));
const Home = lazy(() => import('./dashboard/home'));
const Accounts = lazy(() => import('./dashboard/portal-accounts'));
const PortalAccounts = lazy(() => import('./dashboard/portal-accounts'));
const Kyc = lazy(() => import('../components/kyc'));
const TeamInvite = lazy(() => import('./auth/team-invite'));
const AccountSettings = lazy(() => import('./dashboard/account-settings'));
const DashOnboardingBusinessDocs = lazy(
  () =>
    import('./dashboard/get-started/onboardingChecklist/business-docs-form'),
);
const DashOnboardingBusinessInfo = lazy(
  () =>
    import('./dashboard/get-started/onboardingChecklist/business-info-form'),
);
const DashOnboardingCompleteProfile = lazy(
  () =>
    import('./dashboard/get-started/onboardingChecklist/complete-profile-form'),
);
const DashOnboardingCreateProfile = lazy(
  () =>
    import(
      '../components/Dashboard/get-started/onboarding-checklist/CreateProfileForm'
    ),
);
const DashOnboardingCompleteUBOInfo = lazy(
  () => import('./dashboard/get-started/onboardingChecklist/complete-ubo-info'),
);
const DashOnboardingOwnershipInfo = lazy(
  () => import('./dashboard/get-started/onboardingChecklist/ownershipInfo'),
);
const DashOnboardingVerifyIdentity = lazy(
  () => import('./dashboard/get-started/onboardingChecklist/verify-identity'),
);
const WalletsConfimrSetupDetails = lazy(
  () =>
    import(
      './dashboard/get-started/onboardingChecklist/walletSetup/confirm-setup-setails'
    ),
);
const WalletsSetupHowItWorks = lazy(
  () =>
    import(
      './dashboard/get-started/onboardingChecklist/walletSetup/how-it-works'
    ),
);
const WalletsSetupAccounts = lazy(
  () =>
    import(
      './dashboard/get-started/onboardingChecklist/walletSetup/setup-accounts'
    ),
);
const WalletsSetupWallets = lazy(
  () =>
    import(
      './dashboard/get-started/onboardingChecklist/walletSetup/setupWallets'
    ),
);
const PortalInActivateAccounts = lazy(
  () =>
    import('./dashboard/portal-accounts/portal-in/portal-in-activate-account'),
);
const PortalInBankAccounts = lazy(
  () => import('./dashboard/portal-accounts/portal-in/portal-in-bank-details'),
);
const PortalInDetailsCont = lazy(
  () => import('./dashboard/portal-accounts/portal-in/portal-in-details'),
);
const PortalInSettlementInformation = lazy(
  () =>
    import('./dashboard/portal-accounts/portal-in/portal-in-settlement-info'),
);
const PortalInAccounts = lazy(
  () => import('./dashboard/portal-accounts/portal-in/portal-into-stablecoin'),
);
const PortalOutDetailsCont = lazy(
  () => import('./dashboard/portal-accounts/portal-out/portal-out-details'),
);
const PortalOutSettlementInformation = lazy(
  () =>
    import('./dashboard/portal-accounts/portal-out/portal-out-settlemen-info'),
);
const PortalOutAccounts = lazy(
  () => import('./dashboard/portal-accounts/portal-out/portal-out-to-fiat'),
);
const PortalOutWalletAccounts = lazy(
  () =>
    import('./dashboard/portal-accounts/portal-out/portal-out-wallet-account'),
);
const PortalOutActivateWallets = lazy(
  () =>
    import(
      './dashboard/portal-accounts/portal-out/portal-out-wallet-activation'
    ),
);
const Teams = lazy(() => import('./dashboard/teams'));
const Transactions = lazy(() => import('./dashboard/transactions'));
const Wallet = lazy(() => import('./dashboard/wallets'));
const WalletDetail = lazy(() => import('./dashboard/wallets/walletDetails'));
const NotFoundPage = lazy(() => import('./errors/notFoundPage'));
const NotificationPage = lazy(() => import('./dashboard/notification'));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/not-authorized" element={<CantView />} />
      <Route
        path="kyc/:userId"
        element={
          <PublicRoute>
            <Kyc />
          </PublicRoute>
        }
      />
      <Route path="kyc-link/:userId" element={<Kyc />} />

      <Route path="/" element={<Navigate to="login" replace />} />
      <Route
        path="/signup"
        element={
          <PublicRoute>
            <AuthSignup />
          </PublicRoute>
        }
      />
      <Route
        path="/email-verification/:email"
        element={
          <PublicRoute>
            <AuthEmailVerification />
          </PublicRoute>
        }
      />
      <Route
        path="/team-invite/:token"
        element={
          <PublicRoute>
            <TeamInvite />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <AuthLogin />
          </PublicRoute>
        }
      />
      <Route
        path="/login-verification/:email"
        element={
          <PublicRoute>
            <AuthLoginOtp />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password-link"
        element={
          <UnprotectedRoute>
            <AuthResetPasswordLink />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-email-sent/:email"
        element={
          <UnprotectedRoute>
            <AuthResetEmailSent />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-link-error"
        element={
          <UnprotectedRoute>
            <ResetLinkError />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <UnprotectedRoute>
            <AuthResetPassword />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/get-started/onboarding-checklist/create-profile"
        element={
          <UnprotectedRoute>
            <DashOnboardingCreateProfile />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/get-started/account-type/:email"
        element={
          <UnprotectedRoute>
            <DashAccountType />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<Navigate to="home" replace />} />
        <Route path="home/*" index element={<Home />} />
        <Route path="wallet/*" element={<Wallet />} />
        <Route
          path="/dashboard/wallet/:wallet-wallet-details"
          element={<WalletDetail />}
        />
        <Route path="portal-accounts/*" element={<Accounts />} />
        <Route path="portal-accounts/*" element={<PortalAccounts />} />
        <Route path="account-settings/*" element={<AccountSettings />} />
        <Route path="notifications" element={<NotificationPage />} />
        <Route path="transactions/*" element={<Transactions />} />
        <Route path="teams/*" element={<Teams />} />
        <Route path="help-and-faq/*" element={<HelpandFaq />} />
      </Route>

      {/* Onboarding routes */}
      <Route
        path="get-started/*"
        element={
          <ProtectedRoute>
            <GetStarted />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/complete-profile"
        element={
          <ProtectedRoute>
            <DashOnboardingCompleteProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/get-started/onboarding-checklist/business-information"
        element={
          <ProtectedRoute>
            <DashOnboardingBusinessInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/get-started/onboarding-checklist/business-documents"
        element={
          <ProtectedRoute>
            <DashOnboardingBusinessDocs />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/ownership-informations"
        element={
          <ProtectedRoute>
            <DashOnboardingOwnershipInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/verify-identity"
        element={
          <ProtectedRoute>
            <DashOnboardingVerifyIdentity />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/complete-ubo-information"
        element={
          <ProtectedRoute>
            <DashOnboardingCompleteUBOInfo />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/how-it-works"
        element={
          <ProtectedRoute>
            <WalletsSetupHowItWorks />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/setup-accounts"
        element={
          <ProtectedRoute>
            <WalletsSetupAccounts />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/setup-wallets"
        element={
          <ProtectedRoute>
            <WalletsSetupWallets />
          </ProtectedRoute>
        }
      />

      <Route
        path="/get-started/onboarding-checklist/wallets-setup/confirm-details"
        element={
          <ProtectedRoute>
            <WalletsConfimrSetupDetails />
          </ProtectedRoute>
        }
      />

      {/* Portal Accounts Route */}
      <Route
        path="/dashboard/portal-accounts/portal-into-stablecoin"
        element={
          <ProtectedRoute>
            <PortalInAccounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-out-to-fiat"
        element={
          <ProtectedRoute>
            <PortalOutAccounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-in-settlement-information"
        element={
          <ProtectedRoute>
            <PortalInSettlementInformation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-out-settlement-information"
        element={
          <ProtectedRoute>
            <PortalOutSettlementInformation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-in-activate-accounts"
        element={
          <ProtectedRoute>
            <PortalInActivateAccounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-out-activate-wallets"
        element={
          <ProtectedRoute>
            <PortalOutActivateWallets />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-in-bank-acccount"
        element={
          <ProtectedRoute>
            <PortalInBankAccounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-out-wallet-acccounts"
        element={
          <ProtectedRoute>
            <PortalOutWalletAccounts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-in-details"
        element={
          <ProtectedRoute>
            <PortalInDetailsCont />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/portal-accounts/portal-out-details"
        element={
          <ProtectedRoute>
            <PortalOutDetailsCont />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
