import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

// Define the context value type
interface AmountVisibilityContextType {
  isAmountVisible: boolean;
  setIsAmountVisible: React.Dispatch<React.SetStateAction<boolean>>;
  toggleAmountVisibility: () => void;
}

// Create the context with an undefined default value
const AmountVisibilityContext = createContext<
  AmountVisibilityContextType | undefined
>(undefined);

// Define the provider's props
interface AmountVisibilityProviderProps {
  children: ReactNode;
}

// Create the provider component
export const AmountVisibilityProvider: React.FC<
  AmountVisibilityProviderProps
> = ({ children }) => {
  const [isAmountVisible, setIsAmountVisible] = useState<boolean>(() => {
    // Load initial value from local storage or default to `true`
    const storedValue = localStorage.getItem('isAmountVisible');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });

  const toggleAmountVisibility = () => {
    setIsAmountVisible(prevState => {
      const newState = !prevState;
      localStorage.setItem('isAmountVisible', JSON.stringify(newState)); // Save to local storage
      return newState;
    });
  };

  // Update local storage whenever `isAmountVisible` changes
  useEffect(() => {
    localStorage.setItem('isAmountVisible', JSON.stringify(isAmountVisible));
  }, [isAmountVisible]);

  return (
    <AmountVisibilityContext.Provider
      value={{ isAmountVisible, setIsAmountVisible, toggleAmountVisibility }}
    >
      {children}
    </AmountVisibilityContext.Provider>
  );
};

// Custom hook for consuming the context
export const useAmountVisibility = (): AmountVisibilityContextType => {
  const context = useContext(AmountVisibilityContext);
  if (!context) {
    throw new Error(
      'useAmountVisibility must be used within an AmountVisibilityProvider',
    );
  }
  return context;
};
