import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenValid, userDecodedDetail } from '../../components/hooks/auth';
import { trpc } from '../../lib/trpc';

export const ProtectedRoute = ({ children }: { children?: ReactNode }) => {
  const { authId } = userDecodedDetail();

  const { data: user } = trpc.user.getAccountType.useQuery(
    {},
    { enabled: !!authId },
  );

  if (!authId || !isTokenValid()) {
    return <Navigate to="/login" replace />;
  }

  if (user?.isAccountActivated === false) {
    return <Navigate to="/not-authorized" replace />;
  }

  return <>{children}</>;
};

export const UnprotectedRoute = ({ children }: { children: JSX.Element }) => {
  // const { authId } = userDecodedDetail();

  // const { data: user } = trpc.user.getAccountType.useQuery(
  //   {},
  //   { enabled: !!authId },
  // );
  if (isTokenValid()) {
    return <> {children}</>;
  } else {
    return <Navigate to="/login" replace />;
  }
};

interface PublicRouteProps {
  children: ReactNode;
}

export const PublicRoute = ({ children }: PublicRouteProps) => {
  const { authId } = userDecodedDetail();

  const { data: user } = trpc.user.getAccountType.useQuery(
    {},
    { enabled: Boolean(authId) }
  );

  const isAuthorized =
    isTokenValid() &&
    user?.isAccountActivated &&
    ['CORPORATE', 'INDIVIDUAL'].includes(user?.acountType || '');

  if (isAuthorized || isTokenValid()) {
    return <Navigate to="/not-authorized" replace />;
  }

  return <>{children}</>;
};